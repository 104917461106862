import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IProductDTO, UserInput, UserInputErrors} from '../types/app-types';
import {
  validateUserInputs,
  formatCustomTextFields,
  actualPrice,
  actualSku,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {getProductBySlug, getDefaultProduct} from './getProduct';
import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/es/src/constants';
import {GetProductBySlugQuery, GetFirstProductQuery} from '../graphql/queries-schema';
import {ProductOptionsService} from '@wix/wixstores-client-core/dist/es/src/productOptions/ProductOptionsService';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {IProduct} from '@wix/wixstores-graphql-schema';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {IAddToCartSuccess} from '@wix/wixstores-client-storefront-sdk/dist/es/src/types/native-types';

export interface ProductVariantInfo {
  variantSelectionIds: number[];
  selectionsAvailability: IProductSelectionAvailabilityMap;
  mediaItems: IProduct['media'];
  priceInfo: Pick<IProduct, 'formattedPrice' | 'price' | 'comparePrice' | 'formattedComparePrice'>;
}

export interface ProductServiceProps {
  handleSelectionsChange: () => void;
  variantInfo: ProductVariantInfo;
}

export class ProductService {
  public options?: ProductOptionsService;
  private product: IProductDTO;
  private readonly withPriceRange: boolean;
  /**
   * @deprecated
   */
  private readonly shouldMutateSelections: boolean;
  private readonly siteStore: SiteStore;

  constructor({
    siteStore,
    shouldMutateSelections,
    withPriceRange,
  }: {
    siteStore: SiteStore;
    shouldMutateSelections?: boolean;
    withPriceRange: boolean;
  }) {
    this.siteStore = siteStore;
    this.withPriceRange = withPriceRange;
    this.shouldMutateSelections = shouldMutateSelections;
  }

  public updateOptions(product: IProductDTO): void {
    this.product = product;
    this.options = new ProductOptionsService({product, shouldMutateSelections: this.shouldMutateSelections});
  }

  public async getProductBySlug(slug: string, externalId: string): Promise<GetProductBySlugQuery> {
    const {data} = await getProductBySlug(this.siteStore, slug, externalId, this.withPriceRange);

    return data;
  }

  public async getDefaultProduct(externalId: string): Promise<GetFirstProductQuery> {
    const {data} = await getDefaultProduct(this.siteStore, externalId, this.withPriceRange);

    return data;
  }

  public validate(userInputs: UserInput): UserInputErrors {
    return validateUserInputs(this.product, userInputs);
  }

  public shouldNavigateToCart(): boolean {
    return this.siteStore.cartActions.shouldNavigateToCart();
  }

  /* istanbul ignore next: todo: test */
  public addToCart(
    product: IProductDTO,
    userInputs: UserInput,
    addToCartAction: AddToCartActionOption = AddToCartActionOption.MINI_CART,
    origin: string,
    onSuccess: IAddToCartSuccess,
    selectedVariant: IOptionSelectionVariant
  ): Promise<any> {
    const customTextFields = formatCustomTextFields(product, userInputs);
    const selectionIds = userInputs.selection.map((s) => s.id);
    const quantity = userInputs.quantity[0];

    return this.siteStore.cartActions.addToCart(
      product.id,
      selectionIds,
      quantity,
      customTextFields,
      addToCartAction,
      origin,
      onSuccess,
      {
        id: product.id,
        sku: actualSku(product, selectedVariant),
        type: product.productType,
        name: product.name,
        price: actualPrice(product, selectedVariant),
      }
    );
  }
}
